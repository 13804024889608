<template>
  <div class="">
    <div class="text-2xl font-bold flex justify-center pb-8">
      Get started with your account
    </div>

    <div class="w-552">
      <div class="flex gap-4 w-full">
        <div class="w-full">
          <label
            for="fullname"
            class="block text-sm font-medium text-gray-700"
          >FULL NAME</label>

          <div class="mt-1">
            <input
              type="text"
              name="fullname"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="John Doe"
            >
          </div>
        </div>

        <div class="w-full">
          <label
            for="email"
            class="block text-sm font-medium text-gray-700"
          >EMAIL</label>

          <div class="mt-1">
            <input
              type="email"
              name="email"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="john@ron.digital"
            >
          </div>
        </div>
      </div>

      <div class="flex gap-4 w-full pt-6">
        <div class="w-full">
          <label
            for="password"
            class="block text-sm font-medium text-gray-700"
          >PASSWORD</label>

          <div class="mt-1">
            <input
              type="password"
              name="password"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Password"
            >
          </div>
        </div>

        <div class="w-full">
          <label
            for="confirm-password"
            class="block text-sm font-medium text-gray-700"
          >CONFIRM PASSWORD</label>

          <div class="mt-1">
            <input
              type="Password"
              name="confirm-password"
              class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Confirm Password"
            >
          </div>
        </div>
      </div>

      <div class="text-gray-600 text-xs pt-6">
        By signing up, I agree to the Fexploit Privacy Policy and Terms of Service.
      </div>

      <div class="pt-5 flex items-center justify-center">
        <button
          type="submit"
          class="w-80 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Register
        </button>
      </div>
    </div>

    <div class="pt-4 flex justify-center items-center">
      Already have an account?  
      <router-link
        :to="{ name: 'Login' }"
        class="font-medium text-indigo-600 hover:text-indigo-500 pl-2"
      >
        Log in 
      </router-link>
    </div>
  </div>
</template>